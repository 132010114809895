import React from "react";
import styles from "./UpButton.module.css";

export default function UpButton({ href }) {

    const handlerUP = () => {
        window.scrollTo(0, 0);
    };

    return (
        <button className={styles.btn} onClick={handlerUP}></button>
    );
}